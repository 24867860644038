import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "How Does Volusion Work?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Volusion allows you to manage the online store from your phone with its great mobile app. It means you can check inventory, snap product photos or update prices from your phone without any hassle."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Who Uses Volusion?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Volusion is currently powering over 40,000 online retailers as it is one of the top eCommerce platforms in the world. If you are looking for a functional and easy-to-manage website, Volusion offers a quick setup and easy-going interface. Codestaff Volusion professional can help you."
        }
    }
}

const Volusion = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Volusion Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Volusion Developers. Top
                                    companies and start-ups choose Codestaff professional Volusion Developers
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Volusion Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE VOLUSION DEVELOPERS' banner='/volusion.png' bannerAlt='volusion banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default Volusion