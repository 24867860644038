import React from 'react'
import Layout from '../components/layout'
import Volusion from '../components/categories/volusion'
import Seo from '../components/seo'

const VolusionPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Volusion />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Volusion Freelancers | Codestaff'
  const description =
    'Hire the best Volusion freelancers at Codestaff. Get the top 1% of Volusion professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default VolusionPage
